.firstContainer {
    margin-bottom: 20px;
    .row {
        display: flex;
        margin-bottom: 10px;
    }
    .title {
        width: 400px;
        font-family: UntitledSansMedium, sans-serif, system-ui;
    }
}
.dividerTitle {
    font-size: 24px;
    margin-bottom: 20px;
}
.secondContainer {
    margin-bottom: 20px;
    .columnCont {
        display: flex;
        flex-direction: column;
        .rowCont {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .title {
                width: 400px;
                font-family: UntitledSansMedium, sans-serif, system-ui;
            }
        }
        .subtitle {
            margin-top: -10px;
            font-size: 12px;
            font-family: UntitledSansMedium, sans-serif, system-ui;
            width: 300px;
            margin-bottom: 10px;
        }
    }
}
.error {
    color: #b52b32;
    font-family: UntitledSansMedium, sans-serif, system-ui !important;
}
