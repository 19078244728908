.existingRowContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
    margin-top: 4px;
    &:last-child {
        margin-bottom: 0;
    }

    .textfield {
        label {
            top: -4px;
        }
        div {
            padding-right: 6px;
        }
    }

    .icon {
        padding: 0 !important;
    }
}
