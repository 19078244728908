@use 'src/styles/fonts';

.criteriaGroupContainer {
    .rowsContainer {
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        border: 1px solid gray;
        padding: 8px 16px 16px 0;
        margin: 0 24px;
        border-bottom-right-radius: '0';

        .indRowContainer {
            display: flex;
            flex-direction: column;
            .removeQueryTerm {
                margin-left: auto;
                text-decoration: underline;
                cursor: pointer;
                font-size: 13px;
                margin-top: 10px;
            }

            hr {
                margin: 24px 0 12px 0;
                border: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                width: 75%;
                align-self: center;
            }

            .inputFieldContainer {
                display: flex;
                column-gap: 16px;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    .container2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .addFieldContainer {
            background-color: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid gray;
            border-top: none;
            border-radius: 0 0 4px 4px;
            width: fit-content;
            padding: 0 16px;
            z-index: 2;
            cursor: pointer;

            .addText {
                font-family: fonts.$font-untitled-sans-medium;
                font-size: 14px;
            }

            .addFieldButton {
                margin: 8px;
            }
        }
        .addCriteriaButton {
            cursor: pointer;
        }
        .searchButtonContainer {
            margin-right: 38px;
            margin-top: -1px;
            display: flex;
            .searchButton {
                height: 62.16px;
                background-color: black;
                color: white;
                width: 200px;
                border-radius: 0 0 4px 4px;
                font-family: UntitledSansMedium, sans-serif, system-ui;
                display: flex;
                font-size: 14px;
                &:hover {
                    color: black;
                    border: 1px solid gray;
                    background-color: white;
                }
                .icon {
                    height: 18px;
                    margin-left: 15px;
                }
            }
        }
    }
}
