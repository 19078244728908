.form {
    margin: 0 40px;
    display: flex;
    flex-direction: row;
    .title {
        font-size: 32px;
        width: 20%;
        margin-right: auto;
    }
    .searchContainer {
        display: flex;
        flex-direction: column;
        margin-top: 21.44px;
        margin-right: 30px;
        .rbContainer {
            margin-top: 10px;
        }
    }
    .buttonContainer {
        margin-top: 50px;
    }
}
.hr {
    height: 0;
    border-top: 1px solid black;
    margin: 8px 40px;
}
.container {
    display: flex;
    justify-content: center;
    .resultsContainer {
        display: flex;
        margin-left: auto;
        margin-right: 40px;
        align-items: center;
        .resultsText {
            margin: 0;
            font-size: 14px;
        }
    }
}
