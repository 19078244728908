@use 'src/styles/util';

.splash {
    @include util.center;
    height: 100vh;
    width: 100vw;

    .splash_container {
        animation-name: fade-in;
        animation-duration: 2s;
        display: flex;
        flex-direction: column;

        img {
            height: 100px;
            width: 100px;
            align-self: center;
        }

        .progress {
            align-self: center;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
