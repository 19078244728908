.filesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .cellLink {
        color: black;
    }
    .noResultsCell {
        text-align: center;
        height: 48px;
    }
}
