@use 'src/styles/fonts';

.tableContainer {
    border: 1px solid gray;
    border-radius: 4px;

    .headerTableCell {
        background-color: rgba(229, 229, 229, 1);

        .headerText {
            font-family: fonts.$font-untitled-sans-bold;
        }

    }

    .resultsTableCell {
        height: 64px;
        padding: 0 16px;

        &:hover {
            background-color: rgba(229, 229, 229, 0.5);

            .copyButton {
                color: black;
            }
        }

        .cellContentContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;

            .cellText {
                font-size: 14px;
            }
        }

        .copyButton {
            color: transparent;
        }
    }

    .pageLinkHeader {
        background-color: rgba(229, 229, 229, 1);
    }
}