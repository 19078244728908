.searchAndReloadColumn {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 50%;
    .titleContainer {
        display: flex;
        flex-direction: row;
        margin-right: 1.5%;
        margin-bottom: 10px;
        .title {
            margin-left: 20px;
            font-size: 24px;
            font-weight: 900;
        }
    }
    .productSearchRow {
        display: flex;
        flex-direction: row;
        margin: 0 20px;
        .searchButtonContainer {
            margin-left: 20px;
        }
    }
    .productLookupResponseContainer {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        height: 80px;
        .productLookupInfoRow {
            display: flex;
            flex-direction: row;
            margin-right: 10px;
            align-items: center;
            .label {
                text-decoration: underline;
                font-size: 16px;
                font-weight: 900;
            }
            .value {
                margin-left: 5px;
                width: auto;
            }
            .productNameValue {
                margin-left: 5px;
                width: 375px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .reloadButtonContainer {
            align-self: center;
            margin: 0 20px 0 auto;
        }
    }
}

.historyPanel {
    display: flex;
    flex-direction: column;
    width: 50%;
    border: 1px dashed rgb(196, 196, 196);
    border-radius: 5px;
    margin: 20px auto 0 auto;
    .panelHeader {
        font-size: 24px;
        font-weight: 900;
        text-align: center;
    }
    .listItem {
        font-size: 18px;
        .itemContainer {
            display: flex;
            flex-direction: row;
            .itemId {
                margin: 0 20px 0 10px;
                font-size: 18px;
                width: 100px;
            }
            .itemPrefix {
                margin-right: 20px;
                font-size: 18px;
                width: 100px;
            }
            .itemName {
                font-size: 18px;
                margin-right: 20px;
                width: 500px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
