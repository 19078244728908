.logo {
    height: 3vmin;
    margin-left: 20px;
    pointer-events: none;
    flex-grow: '1';
    cursor: 'pointer';
    color: black;
}

.menuBar {
    display: flex;
    margin-right: 15px;
    align-items: center;

    .menuList {
        display: flex;
        align-items: center;

        .dropdownContainer {
            display: flex;
            flex-direction: row;
            margin-top: 2px;
            align-items: center;
            margin-right: 30px;

            &:hover {
                cursor: pointer;
            }

            .label {
                line-height: 1.25;
                font-size: 16px;
                font-family: UntitledSansMedium, UntitledSans, sans-serif, system-ui;
                color: black;

                &:hover {
                    border-bottom: 1px solid black;
                    color: gray;
                    margin-bottom: -1px;
                }
            }
        }

        .menuItems {
            margin-left: 0px;
            margin-right: 30px;
            font-size: 16px;
        }
    }

    .partition {
        border-left: 1px solid #afa99f;
        height: 24px;
    }

    .logoutStyle {
        margin-left: 30px;
        font-size: 16px;
    }
}