@font-face {
    font-family: 'UntitledSans';
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('webFonts/UntitledSansRegular/untitled-sans-web-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSansBold';
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    font-display: swap;
    src: url('webFonts/UntitledSansBold/untitled-sans-web-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSansMedium';
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: url('webFonts/UntitledSansMedium/untitled-sans-web-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSansBold';
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('webFonts/UntitledSansBold/untitled-sans-web-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSansMedium';
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('webFonts/UntitledSansMedium/untitled-sans-web-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSansMedium';
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    font-display: swap;
    src: url('webFonts/UntitledSansMedium/untitled-sans-web-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSansBold';
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: url('webFonts/UntitledSansBold/untitled-sans-web-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSans';
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    font-display: swap;
    src: url('webFonts/UntitledSansRegular/untitled-sans-web-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'UntitledSans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: url('webFonts/UntitledSansRegular/untitled-sans-web-regular.woff2') format('woff2');
}

$font-untitled-sans: UntitledSans, sans-serif, system-ui;
$font-untitled-sans-medium: UntitledSansMedium, $font-untitled-sans;
$font-untitled-sans-bold: UntitledSansBold, $font-untitled-sans;