@use 'src/styles/fonts';

.container {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    min-height: 40px;
    height: fit-content;

    .label {
        max-width: 240px;
        width: 100%;
        font-size: 13px;
        font-weight: bold;
        font-family: fonts.$font-untitled-sans-bold;
    }

    .inputInformationContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        column-gap: 12px;
        width: 100%;

        .inputContainer {
            display: flex;
            width: 100%;

            .lookUpButton {
                text-decoration: underline;
                text-wrap: nowrap;
                font-family: fonts.$font-untitled-sans-medium;

                &:hover {
                    background-color: white;
                }
            }
        }

        .informationalText {
            width: 100%;
            display: flex;
        }
    }
}
