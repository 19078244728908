.titleResetContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pageTitle {
        margin-bottom: 16px;
    }
}
.primaryContainer {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 8px;
    .primarySelect {
        display: flex;
        flex-direction: row;
    }
}
.dropdownsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;
    .levelContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
        label {
            margin: 0;
            margin-right: 8px;
            span {
                padding: 0;
            }
        }
    }
}

.selectAllContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    column-gap: 2px;
    margin-bottom: 12px;

    label {
        margin: 0;
        margin-right: 8px;
        span {
            padding: 0;
        }
    }
}

.noResultsTableContainer {
    border: 1px solid black;
}
.tableContainer {
    border-right: 1px solid black;
    border-radius: 4px;

    table {
        border-collapse: collapse;
        thead > tr > th {
            background-color: lightgray;
            border-top: 1px solid black;
            border-bottom: none;
            &:first-child {
                background-color: white;
                border-top: none;
            }
            &:nth-child(2) {
                border-left: 1px solid black;
            }
        }
        tbody > tr {
            &:hover {
                background-color: rgba(229, 229, 229, 0.5);
            }
            .checkboxCell {
                border-bottom: none;
                background-color: white !important;
            }
            td {
                padding-top: 6px;
                padding-bottom: 6px;
                &:first-child {
                    margin: 0;
                    margin-left: 2px;
                    border-right: 1px solid black;
                }
                &:last-child {
                    text-align: center;
                }
            }
            &:last-child {
                td {
                    border-bottom: 1px solid black;
                    &:first-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .colSmall {
            min-width: 64px;
        }
        .colChannel {
            min-width: 88px;
        }
        .colMedium {
            min-width: 148px;
        }
        .colDescription {
            width: 100%;
        }
        .checkboxCell {
            width: fit-content;
            padding-left: 0;
            padding-right: 0;
            text-align: center;

            label {
                margin: 0;
                margin-right: 8px;
                span {
                    padding: 0;
                }
            }
        }
        .noResultsHeaderCell {
            background-color: lightgray !important;
            border-width: 0 0 1px 0 !important;
            border-color: black !important;
        }
        .noResultsCell {
            text-align: center;
            height: 48px;
            border-right: none !important;
        }
        .noCheckboxColumn {
            border-right: none !important;
            border-left: none !important;
            border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
        }
    }
}
