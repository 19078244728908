.colSmall {
    min-width: 64px;
}
.colMedium {
    min-width: 148px;
}
.colDescription {
    width: 100%;
}
.colIcon {
    padding: 0 8px !important;
}
.noResultsCell {
    text-align: center !important;
    border-right: none !important;
}
.validProduct {
    &:hover {
        background-color: rgba(229, 229, 229, 0.5);
    }
}
.invalidProduct {
    background-color: rgba(255, 204, 203, 0.5);
    &:hover {
        background-color: rgba(255, 204, 203, 0.85) !important;
    }
}
