@use 'src/styles/colors';
@use 'src/styles/spacing';
@use 'src/styles/responsive';
@use 'src/styles/fonts';

body {
    margin: 0;
    font-family: fonts.$font-untitled-sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: colors.$color-scrollbar colors.$color-border;
}

*::-webkit-scrollbar {
    height: spacing.$scrollbar-width;
    width: spacing.$scrollbar-width;
}

*::-webkit-scrollbar-track {
    background: colors.$color-border;
}

*::-webkit-scrollbar-thumb {
    background-color: colors.$color-scrollbar;
    border-radius: 6px;
    border: 3px solid colors.$color-border;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    color: #000;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

.global-no-outline {
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
}
