@use 'src/styles/fonts';
@use 'src/styles/responsive';

.container {
    display: flex;
    column-gap: 24px;
    width: 100%;
    .imageContainer {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 60%;
        .image {
            display: flex;
            justify-content: center;
            position: relative;
            max-height: 920px;
            min-height: 200px;
            width: 100%;
            background-color: white;
            border-radius: 4px;

            img {
                object-fit: contain;
                max-width: 100%;
            }
            .trashIcon {
                position: absolute;
                border-radius: 50%;
                background-color: rgb(241, 240, 239, 0.5);
                border: 1px solid black;
                right: 8px;
                top: 8px;
            }
        }
    }
    .buttonColumnContainer {
        display: flex;
        flex-direction: column;
        width: 400px;
        row-gap: 12px;
        .label {
            font-size: 13px;
            font-family: fonts.$font-untitled-sans-medium;
        }
        .buttons {
            display: flex;
            column-gap: 12px;
            margin-top: 8px;
            .uploadIcon {
                height: 20px;
                overflow: hidden;
            }
        }
        @media (max-width: responsive.$breakpoint-tablet) {
            .buttons {
                flex-direction: column;
                row-gap: 12px;
                button,
                label {
                    width: 100%;
                }
            }
        }
    }
}
