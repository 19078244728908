.productMaintenanceContainer {
    display: flex;
    flex-direction: row;
    .verticalLine {
        width: 1px;
        display: inline-block;
        height: 175px;
        background-color: rgba(196, 196, 196, 1);
        border: 0;
    }
    .productMaintenanceInputSection {
        padding: 5px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 5px;
        }
        .header {
            font-weight: 900;
            text-decoration: underline;
            text-align: center;
            margin-bottom: 10px;
        }
        .inputContainer {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .label {
                align-self: center;
                font-weight: 900;
                width: 20%;
            }
        }
    }
    .royaltyInputSection {
        padding: 0 5px 5px 5px;
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        width: 50%;
        margin-bottom: 20px !important;
        border: dashed 1px rgba(196, 196, 196, 1);
        border-radius: 5px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 5px;
        }
        .headerContainer {
            display: flex;
            flex-direction: row;
            .header {
                font-weight: 900;
                text-decoration: underline;
                text-align: center;
                margin: 5px 0;
                margin-right: auto;
            }
            .buttonContainer {
                align-self: center;
            }
        }
        .royaltiesList {
            display: flex;
            flex-direction: column;
            .royaltyInputRow {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                column-gap: 10px;
                margin-top: 4px;
                div {
                    background-color: rgb(229, 255, 234);
                    padding-right: 0px;
                    svg {
                        padding-right: 6px;
                        width: 24px;
                        vertical-align: middle;
                    }
                }
                label {
                    top: -4px;
                }
            }
        }
    }
}
