@use 'src/styles/responsive';

.specialsContainer {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.workOrderContainer {
    display: flex;
    flex-direction: row;
    width: 100%; /* Set to 100% for flex layout */
    column-gap: 96px;
    @media (max-width: responsive.$breakpoint-small) {
        flex-direction: column; /* Stack items vertically on small screens */
    }
}
