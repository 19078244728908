@use 'src/styles/fonts';

.mainSearchBarContainer {
    display: flex;
    flex-direction: row;
    margin: 0 30% 0 20px;

    .mainSearchBar {
        width: 60%;
        margin-right: 15px;

        .searchIcon {
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

.dateAndTimeInputRow {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;

    .spaceBetweenInputs {
        margin-right: 10px;
        font-family: fonts.$font-untitled-sans-medium;
    }
}

.lable {
    font-family: fonts.$font-untitled-sans-medium;
}