.loadingSpinnerContainer {
    position: absolute;
    left: 48%;
    bottom: 20%;
}

.tableHead {
    background-color: rgba(178, 178, 178, 1);
}

.newFeatureRow {
    background-color: rgba(172, 253, 147, 1);
    height: 40px;
    .newFeatureRowCell {
        border: black solid 1px;
        height: 40px;
        .dropdownContainer {
            height: 40px;
            display: flex;
            .selectComponent {
                height: 40px;
            }
        }
    }
}
.optionSeparator {
    margin: 4px 16px;
    border: none;
    border-top: 1px solid rgba(229, 229, 229, 1);
}
