.hr {
    height: 1px;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgrey;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin: 20px 0;
}

.tab {
    font-size: 20px;
}

.buildFiltersContainer {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
        font-size: 24px;
        margin-right: auto;
    }

    .switchContainer {
        display: flex;
        flex-direction: row;
        margin-right: 20px;

        .rightSwitch {
            margin-right: auto;
        }
    }

    .btnOptional {
        font-size: 16px;
        border: 1px solid #1e1e1e;
        border-radius: 2px;
    }
}

.tableHeaderCell {
    font-family: UntitledSansBold, sans-serif, system-ui !important;
}
