.optionContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    column-gap: 10px;
    .labelContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
        column-gap: 16px;
    }
    .jsonButton {
        width: 48px;
    }
}
.optionHr {
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
    margin: 16px 0;
}
