@use 'src/styles/fonts';

.criteriaGroupContainer {
    position: relative;

    .rowsContainer {
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        border: 1px solid gray;
        border-radius: 4px 4px 4px 0;
        padding: 8px 16px 16px 0;
        margin: 0 24px;

        .indRowContainer {
            display: flex;
            flex-direction: column;

            hr {
                margin: 24px 0 12px 0;
                border: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                width: 75%;
                align-self: center;
            }

            .inputFieldContainer {
                display: flex;
                column-gap: 16px;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    .addFieldContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: -1px 0 0 24px;
        border: 1px solid gray;
        border-top: none;
        border-radius: 0 0 4px 4px;
        width: fit-content;
        padding: 0 16px;
        z-index: 2;

        .addText {
            font-family: fonts.$font-untitled-sans-medium;
        }

        .addFieldButton {
            margin: 8px;
        }
    }
}