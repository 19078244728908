@use 'src/styles/fonts';

body {
    position: 'relative';
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: '100%';

    .title {
        margin-top: 72px;
        margin-bottom: 7px;
    }

    .caption {
        margin-bottom: 28px;
        width: 490px;
    }

    .formContainer {
        width: 490px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 24px;

        .errorMessage {
            color: rgb(208, 64, 33);
            font-family: UntitledSansMedium, sans-serif, system-ui;
        }

        .dateAndTimeInputRow {
            display: flex;
            justify-content: space-between;

            .spaceBetweenInputs {
                margin-right: 20px;
                width: 100%;
            }

            .dateTimeFieldWidth {
                width: 100%;
            }
        }

        p {
            width: 100%;
            font-size: 13px;
        }
    }

    h1 {
        font-size: 50px;
        margin-bottom: 0px;
    }

    p {
        width: 25%;
    }

    h2 {
        font-weight: 400;
    }
}