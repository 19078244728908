.formContainer {
    width: 490px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 24px;
    p {
        width: 100%;
        font-size: 13px;
    }
}
