.modalContainer {
    align-self: center;
    margin-bottom: 20px;
    padding-top: 10px;
    width: fit-content;
    margin: 0 auto;
    .inputsContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .saveButtonContainer {
            margin-left: auto;
            margin-top: 10px;
        }
        .hr {
            width: 98% !important;
            margin: 0.5% 1.5% 0 1.5%;
            background-color: rgba(196, 196, 196, 1);
            border: none;
            height: 1px;
        }
        .errorMessage {
            color: red;
            text-align: center;
            margin-bottom: 10px;
        }
        .featureOptionsAndSaveLine {
            background-color: red;
            display: flex;
            flex-direction: row;
            .sectionHeader {
                font-weight: 900;
                text-decoration: underline;
                margin: 10px auto;
                text-align: center;
                font-size: 18px;
            }
        }
        .labelAndInputRow {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            margin-left: 20px;
        }
    }
}
