.titleContainer {
    display: flex;
    flex-direction: row;
    margin-right: 1.5%;
    .pageTitle {
        margin: 0 0 10px 20px !important;
    }
    .buttonContainer {
        margin-left: auto;
    }
}

.expireHeaderInfoContainer {
    margin: 20px;
}

.loadingSpinnerContainer {
    position: absolute;
    left: 48%;
    top: 50%;
}
