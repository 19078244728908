.horizontalRule {
    margin: 0.5% 1.5% 0 1.5%;
    background-color: rgba(196, 196, 196, 1);
    border: none;
    height: 1px;
}

.optionFieldContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .existingOptionLabel {
        text-align: center;
        align-self: center;
        width: 100%;
        margin: 5px 0;
        .value {
            font-weight: 900;
            font-size: 18px;
        }
    }
    .newOptionFieldLabel {
        font-weight: 900;
        text-decoration: underline;
        align-self: center;
        width: 100%;
        margin: 5px 0;
    }
}

.royaltyLabel {
    font-weight: 900;
    text-decoration: underline;
    align-self: center;
    margin: 5px 5px auto 0;
}

.royaltyContainer {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    .rowContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        column-gap: 10px;
        margin-top: 4px;
        div {
            border-radius: 4px;
            background-color: rgb(229, 255, 234);
            padding-right: 0px;
            svg {
                padding-right: 6px;
                width: 24px;
                vertical-align: middle;
            }
        }
        label {
            top: -4px;
        }
    }
    .royaltyDescription {
        align-self: center;
    }
}

.derivedFeatureContainer {
    margin-top: 10px;
    .titleAndButtonRow {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .titleContainer {
            margin-left: 43%;
            align-self: center;
        }
    }
}
