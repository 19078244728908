@use 'src/styles/fonts';

.mainContainer {
    background-color: #f1f0ef;
    padding: 10px 15px;

    .headerContainer {
        font-size: 20px !important;
        font-family: fonts.$font-untitled-sans !important;
        margin-bottom: 10px !important;
    }

    .dataContainer {
        column-count: 2;
        column-width: 200px;
    }
}