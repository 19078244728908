@use 'src/styles/fonts';
.actionLinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 175px;
    .link {
        width: auto;
        text-decoration: underline;
        color: black;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 10px;
        &:hover {
            font-family: fonts.$font-untitled-sans-bold;
        }
    }
}
