.tableHeaderRow {
    background-color: rgba(178, 178, 178, 1);
    position: sticky;
    box-shadow: inset 0 0 0 0 black, inset 0 -2px 0 black;
    top: 0;
    .productPrefixHeaderCell {
        width: 25%;
        border-right: solid 2px black;
        font-weight: 900;
        font-size: 16px;
    }
    .productDateHeaderCell {
        width: 25%;
        border-left: solid 2px black;
        font-weight: 900;
        font-size: 16px;
    }
    .productNameHeaderCell {
        width: 50%;
        text-align: left;
        font-weight: 900;
        font-size: 16px;
    }
}

.productPrefixCell {
    width: 25%;
    border-right: solid 2px black !important;
}
.productDateCell {
    width: 25%;
    white-space: nowrap;
    border-left: solid 2px black !important;
}

.productNameCell {
    text-align: left;
}
