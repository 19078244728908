.container {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .pageTitle {
            font-size: 50px;
            font-weight: 300;
        }
        .saveContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
        }
    }
    .headerContainer {
        display: flex;
        flex-direction: row;
        column-gap: 96px;
        .headerRightHalfContainer {
            width: 100%;
            display: flex;
            flex-direction: column;

            .warningMessage {
                margin-top: auto;
                display: flex;
                justify-content: flex-end;
                color: rgb(208, 122, 44);
            }
        }
    }
}
