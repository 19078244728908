.tableContainer {
    border: 1px solid black;
    border-radius: 4px;

    thead > tr > th {
        background-color: lightgray;
    }

    tbody > tr {
        td {
            padding-top: 0;
            padding-bottom: 0;
            height: 48px;
        }
        &:hover {
            background-color: rgba(229, 229, 229, 0.5);
        }
    }
}
