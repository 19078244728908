@use 'src/styles/fonts';

.hr {
    height: 1px;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 15px;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 20px 0;

    .headerContainer {
        display: flex;
        flex-direction: row;
        column-gap: 25px;

        justify-content: space-between;
        flex-wrap: wrap;

        .catalogContainer {
            width: 25%;
            margin-bottom: 20px;

            .catalogTitle {
                font-size: 22px;
                margin-bottom: 15px;
            }

            .value {
                font-family: fonts.$font-untitled-sans-medium;
            }

            .arrow {
                margin: 2px 5px;
            }

            .firstCatalog {
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
            }

            .lookUpButton {
                text-decoration: underline !important;
                text-wrap: nowrap;
                font-family: fonts.$font-untitled-sans !important;
                padding: unset !important;
                font-size: 13px !important;

                &:hover {
                    background-color: white !important;
                }
            }
        }
    }
}
