@use 'src/styles/fonts';

.titleButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .buttonContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        column-gap: 16px;

        .backLink {
            background-color: white;
            color: black;
            text-decoration: none;
            border: 1px solid black;
            border-radius: 4px;
            font-family: fonts.$font-untitled-sans-medium;
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 33px;
            width: 78px;
            justify-content: center;

            &:hover {
                background-color: rgba(229, 229, 229, 1);
                border: 1px solid rgba(229, 229, 229, 1);
                color: black;
            }
        }
    }
}

.inputRowContainer {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    width: 100%;

    .inputContainer {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 8px;

        .label {
            white-space: nowrap;
        }

        .disabledLabel {
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.38);
        }

        .textField {
            width: 100% !important;
        }

        .checkbox {
            padding: 0;
        }
    }
}

.searchTableContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .searchBarButtonContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        column-gap: 12px;

        div {
            width: 100%;
        }

        .hierarchyButton {
            position: relative;
            border: 1px solid black;
            border-radius: 4px;
            padding: 7px 8px;

            div {
                background-color: white;
                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                font-size: 10px;
                font-weight: bold;
                position: absolute;
                top: 10px;
                left: 8px;
                width: 10px;
            }

            &:hover {
                div {
                    background-color: rgb(245, 245, 245);
                }
            }
        }
    }
}