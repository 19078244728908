@use 'src/styles/fonts';

.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .pageTitle {
        margin: 0 0 16px 24px;
    }

    .groupDivider {
        display: flex;
        flex-direction: row;
        width: calc(100% - 32px);
        margin: 24px 8px;
        align-items: center;

        hr {
            margin: 16px;
            border: none;
            border-bottom: 1px dashed gray;
            width: 100%;
        }
    }

    .closeIcon {
        position: absolute;
        left: auto;
        right: 18px;
        color: #e62b00;
        z-index: 999;
        height: 40px;

        path {
            stroke: #e62b00;
            stroke-width: 1px;
        }
    }

    .addGroupSubmitContainer {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 0 16px 24px 16px;
        width: 100%;

        .addGroupContainer {
            align-items: center;
            display: flex;
            flex-direction: row;

            .addText {
                font-family: fonts.$font-untitled-sans-medium;
            }

            .addGroupButton {
                margin: 8px;
            }
        }

        .submitContainer {
            position: absolute;
            right: 70px;
        }
    }

    .resultsContainer {
        margin: 0 36px 24px 24px;
        height: fit-content;
        position: relative;

        .loadingContainer {
            margin-top: 72px;
        }

        hr {
            margin: 8px 0 44px 0;
            border: none;
            border-bottom: 1px solid lightgray;
            width: 100%;
        }

        .showRequestContainer {
            .showRequestButtonContainer {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .criteriaGroupOdd {
        :first-child {
            :first-child {
                background-color: white;
                font-family: UntitledSansMedium, sans-serif, system-ui;
            }

            div {
                background-color: white;
            }

            :last-child {
                svg {
                    background-color: initial;
                }
            }
        }
    }

    .criteriaGroupEven {
        :first-child {
            :first-child {
                background-color: rgba(229, 229, 229, 1);
            }

            div {
                background-color: rgba(229, 229, 229, 1);
            }

            :last-child {
                svg {
                    background-color: initial;
                }
            }
        }
    }
}