@use 'src/styles/fonts';

.loading {
    margin-top: 48px;
}

.titleExtractContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    .buttonContainer {
        display: flex;
        flex-direction: row;
        column-gap: 16px;

        .requestsLink {
            background-color: black;
            color: white;
            text-decoration: none;
            border-radius: 4px;
            font-family: fonts.$font-untitled-sans-medium;
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 0 16px;

            &:hover {
                background-color: rgba(229, 229, 229, 1);
                color: black;
            }
        }
    }
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    min-height: 44px;
    width: 100%;

    .selectAllContainer {
        display: flex;
        align-items: center;
        width: fit-content;
        column-gap: 2px;

        .selectAllContainerText {
            font-family: fonts.$font-untitled-sans-medium;
        }

        label {
            margin: 0;
            margin-right: 8px;

            span {
                padding: 0;
            }
        }
    }

    .sortSelectedContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 36px;
        margin-left: auto;

        .selectedContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 12px;
            margin: auto 0 auto auto;

            .selectedContainerText {
                font-family: fonts.$font-untitled-sans-medium;
            }
        }
    }
}