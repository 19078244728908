@use 'src/styles/fonts';

.securedLink {
    background-color: black;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-family: fonts.$font-untitled-sans-medium;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 16px;

    &:hover {
        background-color: rgba(229, 229, 229, 1);
        color: black;
    }
}

.disabledLink {
    background-color: rgba(229, 229, 229, 1);
    color: white;
    cursor: default;
    pointer-events: none;

    &:hover {
        color: white;
    }
}