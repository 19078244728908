@use 'src/styles/fonts';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
    row-gap: 12px;

    .pageContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 30px;

        .titleResetContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .viewLink {
                text-decoration: underline;
                cursor: pointer;
                font-size: 13px;
                margin-top: 15px;
            }
        }

        hr {
            border: none;
            border-bottom: 1px solid lightgray;
            width: 100%;
            margin: 32px 0 12px 0;
        }

        .pageTitle {
            margin-bottom: 16px;
        }

        .primaryContainer {
            display: flex;
            align-items: center;
            column-gap: 16px;
            margin-bottom: 35px;
            justify-content: space-between;

            .primaryTitle {
                font-family: fonts.$font-untitled-sans-medium;
            }

            .radioContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
            }

            .primarySelect {
                display: flex;
                flex-direction: row;
                margin-left: 15px;
            }
        }

        .dateContainer {
            display: flex;
            align-items: center;
            column-gap: 16px;
            margin-bottom: 30px;
            height: 54px;

            .dateField {
                width: 400px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .dateTextField {
                    width: 200px;
                    display: flex;
                    flex-direction: row;
                }

                .checkBoxField {
                    display: flex;
                    align-items: center;

                }
            }

            .primaryTitle {
                font-family: fonts.$font-untitled-sans-medium;
            }
        }

        .dropdownsContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            row-gap: 30px;
            margin-bottom: 15px;
        }

        .resultsContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            column-gap: 24px;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            .container2 {
                display: flex;
                flex-direction: column;
                align-items: center;

                .resultsCount {
                    margin: 7px 0 0 0;
                    font-size: 14px;
                }
            }

            .resultsTitle {
                font-family: fonts.$font-untitled-sans-medium;
                font-size: 13px;
            }

            .sortContainer {
                display: flex;
                align-items: center;

                .selectField {
                    height: 40px;
                    margin: 0px !important;
                }
            }
        }
    }

    h1 {
        font-size: 50px;
        margin-bottom: 0px;
    }
}