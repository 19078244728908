.container {
    margin: 0 40px;
    .container1 {
        display: flex;
        align-items: left;
        .container2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            .resultsCount {
                margin: 7px 0 0 0;
                font-size: 14px;
            }
        }
    }
}
