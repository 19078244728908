@use 'src/styles/fonts';

.pageContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;

    .titleResetContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    hr {
        border: none;
        border-bottom: 1px solid lightgray;
        width: 100%;
        margin: 32px 0 12px 0;
    }

    .pageTitle {
        margin-bottom: 16px;
    }

    .primaryContainer {
        display: flex;
        align-items: center;
        column-gap: 16px;
        margin-bottom: 8px;

        .primaryTitle {
            font-family: fonts.$font-untitled-sans-medium;
        }

        .primarySelect {
            display: flex;
            flex-direction: row;
        }
    }

    .dropdownsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 16px;
    }

    .resultsContainer {
        width: 100%;
        margin: 16px 0;
        display: flex;
        flex-direction: row;
        column-gap: 24px;
        align-items: center;

        .resultsTitle {
            font-family: fonts.$font-untitled-sans-medium;
        }
    }
}