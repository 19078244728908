.container {
    display: flex;
    column-gap: 96px;
    height: fit-content;
    width: 100%;
    .columnContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        .titleContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 24px;
            .title {
                font-size: 20px;
            }
        }
    }
}
