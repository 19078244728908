.contentContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 4px;
    max-height: 80%;
    min-height: 64px;
    z-index: 9999;
    position: relative;
    row-gap: 24px;
    overflow-y: auto;
    overflow-x: hidden;

    .iconContainer {
        position: absolute;
        right: 12px;
        top: 12px;
        display: flex;
        column-gap: 16px;
        .closeIcon {
            z-index: 9999;
            svg {
                stroke: white;
            }
        }
    }
}
