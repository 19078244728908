@use 'src/styles/fonts';

.hr {
    height: 1px;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgrey;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin: 20px 0;
}

.tab {
    font-size: 20px;
}

.buildFiltersContainer {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
        font-size: 24px;
        margin-right: auto;
    }

    .switchContainer {
        display: flex;
        flex-direction: row;
        margin-right: 20px;

        .rightSwitch {
            margin-right: auto;
        }
    }

    .btnOptional {
        font-size: 16px;
        border: 1px solid #1e1e1e;
        border-radius: 2px;
    }
}

.tableHeaderCell {
    font-family: fonts.$font-untitled-sans-bold !important;
    border-bottom: 1px solid #1e1e1e !important;
    .specialHeaderCellContainer {
        display: flex;
        flex-direction: column;
        .titleBox {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .valueWrapper {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #afa99f;
            padding: 10px 8px;
            .sidewaysLabels {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                text-align: center;
                margin: 0;
                font-family: UntitledSansBold, sans-serif, system-ui;
                font-size: 14px;
            }
        }
    }
}

.horizontalHeadings > .tableHeaderCell {
    padding-top: 32px;
}
