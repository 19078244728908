.buildBoxContainer {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 4px;
    .rowOneContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        .productNbrLabel {
            font-family: UntitledSansMedium, sans-serif, system-ui;
            margin-right: 20px;
        }
        .compIncompClass {
            display: flex;
            flex-direction: row;
            align-items: center;
            .label {
                font-family: UntitledSansMedium, sans-serif, system-ui;
                font-size: 12px;
            }
        }
    }
    .rowTwoContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .productNbrVal {
            font-family: UntitledSansMedium, sans-serif, system-ui;
            margin-bottom: 15px;
        }
        .copyButton {
            margin-left: 10px;
            margin-bottom: 12px;
            .icon {
                width: 18px;
                height: 18px;
                color: grey;
            }
        }
    }
    .rowThreeContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        .leadTimeContainer {
            display: flex;
            flex-direction: column;
            width: 50%;
            .title {
                font-family: UntitledSansMedium, sans-serif, system-ui;
                font-weight: 900;
                font-size: 13px;
            }
            .val {
                font-family: UntitledSans, sans-serif, system-ui;
                font-size: 12px;
                color: #1e1e1e;
                height: 34px;
                vertical-align: middle;
            }
        }
    }
    .rowFourContainer {
        display: flex;
        flex-direction: row;
        .innerBox{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .copyButton {
            .icon {
                width: 18px;
                height: 18px;
                color: grey;
            }
        }
        .leadTimeContainer {
            display: flex;
            flex-direction: column;
            width: 50%;
            .title {
                font-family: UntitledSansMedium, sans-serif, system-ui;
                font-weight: 900;
                font-size: 13px;
            }
            .val {
                font-family: UntitledSans, sans-serif, system-ui;
                font-size: 12px;
                color: #1e1e1e;
            }
        }
    }
    .rowFiveContainer {
        display: flex;
        flex-direction: column;
        .title {
            font-family: UntitledSansMedium, sans-serif, system-ui;
            font-weight: 900;
            font-size: 13px;
        }
        .val {
            font-family: UntitledSans, sans-serif, system-ui;
            font-size: 12px;
            color: #1e1e1e;
        }
    }
}
.quickLinkContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    .leftSide {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .rightSide {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 20px;
    }
}
