.container {
    width: 100%;
    display: flex;
    align-items: center;
    .title {
        margin-right: auto;
        margin-left: 40px;
        font-size: 30px;
    }
    .buttonContainer {
        display: flex;
        align-items: end;
        justify-content: end;
        margin-right: 40px;
        width: 35%;
    }
}

.hr {
    margin: 8px 40px;
    background-color: black;
    border: none;
    height: 1px;
    background-color: #000;
}
