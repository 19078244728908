@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin line-clamp($lines-to-show) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
}
