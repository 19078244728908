.headerLeftContainer {
    width: 30%;
}

.dataIcon {
    position: absolute;
    left: 17%;
    cursor: pointer;
    margin-top: 5px;

    @media only screen and (max-width: 768px) {
        left: 20%;
    }

    @media only screen and (min-width: 769px) and (max-width: 1200px) {
        left: 20%;
    }

    @media only screen and (min-width: 1201px) {
        left: 17%;
    }
}