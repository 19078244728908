@use 'src/styles/fonts';


.catalogTitle {
    font-size: 20px !important;
    margin-bottom: 30px !important;
}

.value {
    font-family: fonts.$font-untitled-sans-medium !important;
    font-size: 13px !important;
}

.otherValue {
    font-size: 13px !important;
}

.arrow {
    margin: 1px 5px !important;
    font-size: 13px !important;
}

.firstCatalog {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    font-size: 13px !important;
    padding-bottom: 2px;
}