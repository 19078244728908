.container {
    display: flex;
    flex-direction: row;
    .dropdownContainer {
        height: 40px;
        display: flex;
        align-self: center;
        .selectComponent {
            height: 40px;
            margin: 0 15px;
        }
    }
    .buttonContainer {
        margin-left: auto;
        margin-right: 10px;
    }
}

.unopenedContainer {
    display: flex;
    flex-direction: row;
    .label {
        align-self: center;
    }
    .buttonContainer {
        margin-left: auto;
        margin-right: 10px;
    }
}
.optionSeparator {
    margin: 4px 16px;
    border: none;
    border-top: 1px solid rgba(229, 229, 229, 1);
}
