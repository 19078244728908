.rowContainer {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    align-items: flex-start;
    width: 100%;
    padding-left: 16px;
    .rowOperators {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        .radioGroup {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    .indRow {
        display: flex;
        flex-direction: row;
        width: 100%;
        .fieldSelect {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 20%;
            margin-right: 20px;
            .closeIcon {
                color: #e62b00;
                align-self: flex-start;
                height: 40px;
            }
            .selectLabel {
                background-color: white;
                padding: 0 4px;
            }
        }
        .fieldsContainer {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            column-gap: 10px;
            width: 100%;
            .addFieldIcon {
                height: fit-content;
            }
        }
    }
}
