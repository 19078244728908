@use 'src/styles/fonts';

.labelContainer {
    margin: 16px 0px 16px 0px;

    .labelRow {
        display: flex;
        flex-direction: row;

        .labelColumn {
            display: flex;
            flex-direction: column;
            flex: 0 0 15rem;

            .labelFont {
                font-family: fonts.$font-untitled-sans-bold !important;
                font-size: 16px;
                line-height: 20.8px;
            }
        }
    }
}
