.productHeaderContainer {
    margin: 24px 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    .pageTitleContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .dataIcon {
            height: 48px;
            svg {
                margin-left: 16px;
            }
            button {
                border-radius: 4px;
                border: 1px solid black;
            }
        }
        .pageTitle {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
        }
    }
    .metadataContainer {
        background-color: rgb(229, 229, 229);
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 16px 0;
        border-radius: 4px;
        .metadataRow {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 36px;
            width: 50%;
            .dataSection {
                display: flex;
                width: 100%;
                .label {
                    min-width: 260px;
                    font-weight: bold;
                    text-decoration: underline;
                    text-align: right;
                    margin-right: 24px;
                    white-space: nowrap;
                }
                .arrow {
                    margin: 0 16px;
                }
            }
        }
        .levelsRow {
            width: 100%;
        }
    }

    .separatorContainer {
        width: 100%;
        background-color: rgb(229, 229, 229);
        display: flex;
        justify-content: center;
        .separator {
            width: 95%;
            border: none;
            border-bottom: 1px solid rgb(196, 196, 196);
            height: 1px;
            margin: 0;
            display: flex;
        }
    }
    .featuresContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 48px 0 0 0;
        .featuresLabel {
            font-weight: bold;
            margin-bottom: 12px;
            text-decoration: underline;
            text-align: left;
            width: 65%;
        }
    }
    .noProductsLabel {
        width: 100%;
        text-align: center;
        margin-top: 24px;
    }
}
