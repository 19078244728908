.inputsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .labelAndInputRow {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
}
