.pageTitle {
    margin: 0 0 10px 20px !important;
}

.loadingSpinnerContainer {
    position: absolute;
    left: 48%;
    top: 50%;
}

.dataContainer {
    background-color: rgba(229, 229, 229, 1);
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    .validationFeatureGridContainer {
        border: 1px black solid;
        border-radius: 5px;
    }
}
