.container {
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 24px;
    .titleContainer {
        display: flex;
        width: 100%;
        align-items: center;
        column-gap: 24px;
        .title {
            font-size: 36px;
            font-weight: normal;
            width: fit-content;
            text-wrap: nowrap;
        }
        .buttonContainer {
            display: flex;
            flex-direction: row;
            .buttonSideContainer {
                display: flex;
                flex-direction: row;
                column-gap: 12px;
            }
            justify-content: space-between;
            width: 100%;
        }
    }
}
