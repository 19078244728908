.displayContainer {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 74px);
    height: fit-content;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 24px 24px 24px 48px;
    margin-bottom: 24px;
    pre {
        width: 100%;
    }
    .copyIcon {
        height: 40px;
    }
}
