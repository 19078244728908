 .headerRightHalfContainer {
     width: 30%;
     display: flex;
     flex-direction: column;

     .warningMessage {
         margin-top: auto;
         display: flex;
         justify-content: flex-end;
         color: rgb(208, 122, 44);
     }


 }