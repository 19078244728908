@use 'src/styles/fonts';

.horizontalRule {
    margin: 1% 0 3% 0;
    background-color: rgba(196, 196, 196, 1);
    border: none;
    height: 1px;
}

.buttonAlign {
    text-align: right;
    margin-top: 25px;
}

.addScroll {
    overflow: auto;
    max-height: 58vh;
}

.priceDetailsHeader {
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headerRightText {
        font-size: 16px;
        font-family: fonts.$font-untitled-sans-medium;
    }

    .rightSwitch {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: space-between;
        flex-direction: row;
    }
}

.headerRightHighLight {
    background-color: #F1F0EF;
    padding: 10px;

    .headerRightText {
        font-size: 14px;
        font-family: UntitledSansBold, UntitledSans, sans-serif, system-ui;
        margin-bottom: 5px;
    }

    .headerText {
        font-size: 18px;
        font-family: UntitledSansMedium, UntitledSans, sans-serif, system-ui;
        margin-bottom: 10px;
    }

    .priceErrorText {
        font-size: 13px;
        font-family: UntitledSansMedium, UntitledSans, sans-serif, system-ui;
        color: red;
        margin-top: 3px
    }
}