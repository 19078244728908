.infoFeatureTableContainer {
    border-radius: 4px;
    border: 1px solid gray;
    table {
        thead {
            border-bottom: 2px solid black;
            th {
                background-color: rgb(178, 178, 178);
                border-right: 2px solid black;
                border-bottom: 2px solid black;
                font-size: 16px;
                font-weight: bolder;
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}
