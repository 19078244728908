.pageLinkCell {
    height: 64px;
    padding: 0 0 0 16px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

    .linkContainer {
        &:hover {
            background-color: rgba(229, 229, 229, 0.5);
        }
        justify-content: center;
        align-items: center;
        display: flex;
        width: 72px;
        max-width: 72px;
        height: 100%;
        .pageLink {
            text-decoration: none;
            display: flex;
            text-align: center;
            color: black;
            position: relative;
            justify-self: center;
            font-size: 14px;

            &:hover {
                color: grey;
            }
        }
    }
}
