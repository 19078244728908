html,
body {
    height: 100%;
}

.holder {
    min-height: 100vh;
    position: relative;
    .content {
        padding-bottom: 92px;
        overflow: visible;
    }
    .footer {
        height: 46px;
        width: 100%;
        background-color: black;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
