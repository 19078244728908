@use 'src/styles/fonts';

.headerContainer {
    display: flex;
    flex-direction: row;
    .headerLeftContainer {
        width: 48%;
    }
    .headerRightHalfContainer {
        width: 45%;
        display: flex;
        flex-direction: column;
        .warningMessage {
            margin-top: auto;
            display: flex;
            justify-content: flex-end;
            color: rgb(208, 122, 44);
        }
        .catalogContainer {
            margin-top: 20px;
            .catalogTitle {
                font-size: 20px;
                margin-bottom: 15px;
            }
            .value {
                font-family: fonts.$font-untitled-sans-medium;
                font-size: 13px;
            }
            .arrow {
                margin: 0 4px;
                font-size: 13px;
            }
            .firstCatalog {
                display: -webkit-box;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
    .dataIcon {
        position: absolute;
        left: 20%;
        cursor: pointer;
        margin-top: 5px;
    }
}
.dataContainer {
    background-color: #f1f0ef;
    padding: 30px 40px;
    column-count: 2;
    column-width: 200px;
    .validationFeatureGridContainer {
        border: 1px black solid;
        border-radius: 5px;
    }
}

.buildBoxContainer {
    width: 40%;
    margin-left: auto;
    margin-right: 40px;
}
