.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    row-gap: 12px;

    h1 {
        font-size: 50px;
        margin-bottom: 0px;
    }

    p {
        width: 490px;
    }

    .centeredButton {
        display: flex;
        justify-content: center;
    }

    h2 {
        font-weight: 400;
    }
    .dateAndTimeInputRow {
        display: flex;
        justify-content: space-between;
    }
    
    .spaceBetweenInputs {
        margin-right: 20px;
        width: 100%;
    }
    
    .dateTimeFieldWidth {
        width: 100%;
    }
     }