.dialog {
    .jsonContainer {
        background-color: white;
        display: flex;
        justify-content: flex-start;
        border-radius: 4px;
        max-height: 80%;
        z-index: 9999;
        position: relative;
        overflow: auto;
        pre {
            width: 100%;
            margin: 0;
            padding: 24px 24px 24px 48px;
            overflow-y: scroll;
            &::-webkit-scrollbar-track {
                background-color: transparent !important;
            }
            scrollbar-color: transparent transparent;
        }
        .iconContainer {
            position: absolute;
            right: 24px;
            top: 12px;
            display: flex;
            column-gap: 16px;
            .copyIcon {
                height: 40px;
            }
            .closeIcon {
                height: 40px;
            }
        }
    }
}
