.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
    .dataIcon {
        position: absolute;
        right: 0;
        top: -72px;
        height: 48px;
        svg {
            margin-left: 16px;
        }
        button {
            border-radius: 4px;
            border: 1px solid black;
        }
    }
    .normalSizedRow {
        display: flex;
        flex-direction: row;
        width: 400px;
    }
    .largeSizedRow {
        display: flex;
        flex-direction: row;
        width: 1000px;
    }
    .horizontalRule {
        margin: 0.5% 1.5% 0 1.5%;
        background-color: rgba(196, 196, 196, 1);
        border: none;
        height: 1px;
        width: 98%;
    }
    .levelsAndProductNumberContainer {
        display: flex;
        flex-direction: column;
        .levelsContainer {
            display: flex;
            flex-direction: row;
        }
        .productNumberContainer {
            display: flex;
            flex-direction: row;
            background-color: rgba(190, 251, 148, 1);
            margin: 0 auto;
            padding: 5px;
            border-radius: 5px;
            border: dashed black 1px;
            .text {
                align-self: center;
                margin: 0 auto;
            }
        }
    }

    .infoContainer {
        display: flex;
        flex-direction: row;
        position: relative;
        .productNameModal {
            position: absolute;
            bottom: 25px;
            background-color: rgba(229, 229, 229, 1);
            border-radius: 5px;
            padding: 5px;
            font-weight: 600;
        }
        .productNameValue {
            width: fit-content;
            max-width: 750px;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .returnMessage {
            width: fit-content;
            max-width: 750px;
        }
        .productVersion {
            width: fit-content;
            max-width: 750px;
            overflow-x: hidden;
            white-space: nowrap;
            a {
                text-overflow: ellipsis;
                color: black;
                font-weight: bold;
            }
        }
        .labelContainer {
            font-weight: 900;
            width: 260px;
            text-align: right;
            margin-right: 10px;
            text-decoration: underline;
        }
    }
    .levelsContainer {
        display: flex;
        flex-direction: column;
    }
}
