.cell {
    width: 60px;
    min-width: 60px;
    padding-right: 4px;
    padding-left: 4px;
    .pill {
        font-size: 10px;
        color: #017a9a;
        background-color: #f2f8fa;
    }
}
