.rulesContainer {
    background-color: rgba(229, 229, 229, 1);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    .singleRule {
        border-radius: 5px;
        &:hover {
            background-color: rgba(241, 255, 237, 1);
        }
        .ruleLabel {
            font-weight: 900;
            .capitalizeFirstLetter {
                text-transform: capitalize;
            }
        }
    }
}
.buttonRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .buttonBox {
        margin-left: auto;
        .buttonSpan {
            margin-right: 10px;
        }
    }
}
