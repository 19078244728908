@use 'src/styles/responsive';
.dropZoneContainer {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(175, 169, 159)' stroke-width='4' stroke-dasharray='10%2c12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 24px;
    @media (max-width: responsive.$breakpoint-small) {
        width: 100%;
    }
}
