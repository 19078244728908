@use 'src/styles/fonts';

.Home {
    text-align: center;

    .mainSearchBarContainer {
        display: flex;
        flex-direction: row;
    }

    .mainSearchBar {
        width: 65%;
        margin: 0 8.5% 0 1.5%;

        .searchIcon {
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

.Home-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Home-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.Home-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    max-width: 90%;
    margin: 0 auto;
}

.Home-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
