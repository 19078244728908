@use 'src/styles/fonts';

.headerContainer {
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .headerLeft {
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        margin: 10px;
        font-size: 13px;
        font-weight: bold;
        font-family: fonts.$font-untitled-sans-bold;
    }
}
