.hr {
    height: 1px;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 40px;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin: 20px 0;
}

.tab {
    font-size: 20px;
}

.buildFiltersContainer {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
        font-size: 24px;
        margin-right: auto;
    }
    .switchContainer {
        display: flex;
        flex-direction: row;
        margin-right: 20px;
        .rightSwitch {
            margin-right: auto;
        }
    }

    .btnOptional {
        font-size: 16px;
        border: 1px solid #1e1e1e;
        border-radius: 2px;
    }
}

.tableHeaderCell {
    font-family: UntitledSansMedium, sans-serif, system-ui;
}

.restrictionsBox {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 40px;
    .header {
        text-align: center;
        color: rgb(181, 80, 24);
        font-family: UntitledSansMedium, sans-serif, system-ui;
        font-size: 13px;
    }
}
