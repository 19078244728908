.productHeaderContainer {
    margin: 0 1.5%;
    .productHeaderRow {
        display: flex;
        flex-direction: row;
        .statusText {
            margin: 0;
            font-weight: 900;
            color: red;
        }
    }
    .metadataContainer {
        margin-bottom: 20px;
        .metadataRow {
            display: flex;
            flex-direction: row;
            .productPrefixAndStartDateColumn {
                display: flex;
                flex-direction: column;
                width: 20%;
                .infoContainer {
                    display: flex;
                    flex-direction: row;
                    .label {
                        font-weight: 900;
                        width: 40%;
                    }
                }
            }
            .nameAndVersionColumn {
                display: flex;
                flex-direction: column;
                .infoContainer {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    .productNameModal {
                        position: absolute;
                        bottom: 5px;
                        background-color: rgba(229, 229, 229, 1);
                        border-radius: 5px;
                        padding: 5px;
                        font-weight: 600;
                    }
                    .productNameValue {
                        width: 500px;
                        overflow-x: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .labelContainer {
                        font-weight: 900;
                        width: 75px;
                    }
                }
            }
            .matrixAndWeightClassColumn {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                .priceMatrixContainer {
                    margin-left: 40px;
                    display: flex;
                    flex-direction: row;
                    .priceMatrixLabel {
                        font-weight: 900;
                        width: 100px;
                        align-self: middle;
                    }
                }
            }
        }
        .royaltyContainer {
            display: flex;
            flex-direction: row;
            .royaltyLabel {
                margin-left: auto;
                font-weight: 900;
                width: 111px;
            }
        }
    }
    .buttonContainer {
        display: flex;
        flex-direction: row;
        .publishDiscardButtonContainer {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            column-gap: 10px;
        }
    }
}

.horizontalRule {
    margin: 0.5% 1.5% 0 1.5%;
    background-color: rgba(196, 196, 196, 1);
    border: none;
    height: 1px;
}

.noMarginHorizontalRule {
    background-color: rgba(196, 196, 196, 1);
    border: none;
    height: 1px;
}

.featuresContainer {
    margin-left: 1.5%;
    .featuresButtonsAndSearchContainer {
        display: flex;
        flex-direction: row;
        .searchBarWrapper {
            padding-top: 1%;
        }
    }
}
