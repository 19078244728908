.colSmall {
    min-width: 64px;
}
.colMedium {
    min-width: 148px;
}
.colDescription {
    width: 100%;
}
.colIcon {
    padding: 0 8px !important;
}
.noResultsCell {
    text-align: center !important;
    border-right: none !important;
}
.levelsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}
