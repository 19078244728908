@use 'src/styles/fonts';

.quickPriceHeaderContainer {
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .quickPriceHeaderLeft {
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        margin: 10px;
        font-size: 13px;
        font-weight: bold;
        font-family: fonts.$font-untitled-sans-bold;

        .quickPriceHeaderLeftText {
            font-size: 14px;
            font-family: UntitledSansBold, UntitledSans, sans-serif, system-ui;
            margin-bottom: 10px;
        }
    }

    .priceErrorText {
        font-size: 13px;
        font-family: UntitledSansMedium, UntitledSans, sans-serif, system-ui;
        color: red;
        margin-top: 3px;
    }

    .quickPriceHeaderRight {
        height: fit-content;
        display: flex;
        justify-content: flex-end;
        margin: 10px;

        .headerRightHighLight {
            background-color: #f1f0ef;
            padding: 10px;

            .headerRightText {
                font-size: 14px;
                font-family: UntitledSansBold, UntitledSans, sans-serif, system-ui;
                margin-bottom: 5px;
            }

            .priceErrorText {
                font-size: 13px;
                font-family: UntitledSansMedium, UntitledSans, sans-serif, system-ui;
                color: red;
                margin-top: 3px;
            }
        }
    }
}
