.tableCell {
    border-right: solid 2px black;
}

.descriptionAndIconsContainer {
    display: flex !important;
    flex-direction: row;
    .copyButton {
        margin-left: 16px;
    }
}

.horizontalRule {
    margin: 0.5% 1.5% 0 1.5%;
    background-color: rgba(196, 196, 196, 1);
    border: none;
    height: 1px;
}

.collapse {
    display: flex;
    flex-direction: column;
    width: inherit;
    .topLevelFieldContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        position: relative;
        .featureIdLabel {
            align-self: center;
            text-align: center;
        }
        .featureIdValue {
            font-weight: 900;
            font-size: 18px;
            margin-left: 10px;
            align-self: center;
        }
        .mfgLabel {
            font-weight: 900;
            text-decoration: underline;
            align-self: center;
            width: 100%;
            margin-right: auto;
        }
        .prcLabel {
            font-weight: 900;
            text-decoration: underline;
            align-self: center;
            width: 100%;
        }
        .materialCodeLabel {
            font-weight: 900;
            text-decoration: underline;
            align-self: center;
            width: 100%;
        }
    }
}
