.contentContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    row-gap: 16px;
    .imageContainer {
        display: flex;
        background-color: white;
        justify-content: center;
        border-radius: 4px;
        min-width: 100px;
        width: 100%;
        min-height: 100px;
        img {
            object-fit: contain;
            max-width: 100%;
        }
    }
}
