@use 'src/styles/fonts';

.container {
    font-size: 14px;

    .textFieldHeader {
        display: flex;
        flex-direction: row;
        width: 100%;

        .left {
            text-wrap: nowrap;
            font-size: 16px;
            font-family: fonts.$font-untitled-sans-bold;
            margin-bottom: 5px;
        }

        .leftError {
            text-wrap: nowrap;
            font-size: 16px;
            font-family: fonts.$font-untitled-sans-bold;
            color: rgb(208, 64, 33) !important;
        }

        .right {
            font-size: 13px;
            margin-left: auto;
            text-align: end;
            color: #8c837b;
            font-family: fonts.$font-untitled-sans-bold;
            margin-bottom: 5px;
        }
    }

    .formControl {
        .radioGroup {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            text-wrap: nowrap;
            width: fit-content;

            span {
                font-size: 14px;
                font-family: fonts.$font-untitled-sans-medium;
            }
        }
    }

    .selectOption {
        font-family: fonts.$font-untitled-sans;
    }
}

.readOnlyContainer {
    flex-direction: row;
    display: flex;
    column-gap: 4px;
    align-items: center;

    svg {
        font-size: 20px;
    }

    .readOnlyLabel {
        display: flex;
        align-items: center;
        position: relative;
    }
}
