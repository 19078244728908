.form {
    margin: 0 40px;
    display: flex;
    flex-direction: row;
    .title {
        font-size: 32px;
        width: 20%;
        margin-right: auto;
    }
    .searchContainer {
        display: flex;
        flex-direction: column;
        margin-top: 21.44px;
        margin-right: 30px;
        .rbContainer {
            margin-top: 10px;
        }
    }
    .buttonContainer {
        margin-top: 50px;
    }
}

.hr {
    margin: 50px 40px 8px 40px;
    border: none;
    height: 1px;
    background-color: #000;
}
