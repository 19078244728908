.container {
    width: 100%;

    .criteriaGroupButtonContainer {
        display: flex;
        flex-direction: row;
        margin: 20px 40px 20px 40px;
        justify-content: space-between;

        .viewJsonString {
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }

        .label {
            font-family: UntitledSansMedium, sans-serif, system-ui;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        align-items: center;
    }

    .criteriaGroupContainer {
        position: relative;

        .moreThanOneDecorator {
            display: flex;
            align-items: center;
            margin: 10px 0 20px 0;

            .hr1 {
                width: 100%;
                height: 1px;
                border: none;
                background-color: lightgray;
                margin-left: 40px;
                margin-right: 10px;
            }

            .hr2 {
                width: 100%;
                height: 1px;
                border: none;
                background-color: lightgray;
                margin-right: 40px;
                margin-left: 10px;
            }
        }

        .close {
            color: rgb(167, 55, 55);
        }

        .wrapper {
            margin-left: 16px;
        }
    }

    .resultsContainer {
        margin-right: 40px;
        margin-left: 40px;

        hr {
            margin: 20px 0 8px 0;
            border: none;
            height: 1px;
            background-color: #000;
        }

        .toggleRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .resultsCountContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                column-gap: 24px;
                margin-right: auto;

                .count {
                    font-size: 14px;
                }
            }
        }
    }
}