.featureRow {
    &:hover {
        background-color: rgba(0, 255, 0, 0.1);
    }
    td {
        border-right: 2px solid black;
        padding: 8px 16px;
        &:last-child {
            border-right: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
.jsonButtonContainer {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    svg {
        margin-left: 16px;
    }
    button {
        border-radius: 4px;
        border: 1px solid black;
    }
}
.optionRowContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.depRulesButtonContainer {
    margin: 16px 24px 24px 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    .depRulesContainer {
        padding: 16px;
        background-color: rgb(229, 229, 229);
        border-radius: 4px;
        .depRule {
            width: 100%;
        }
    }
    .showMoreBtn {
        margin-top: 16px;
        align-self: flex-end;
    }
}
