.btnRestric {
    text-decoration: underline;
    font-size: 14;
    margin: 0;
    color: #b05300 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btnRoyalty {
    text-decoration: underline;
    font-size: 14;
    margin: 0;
    color: #017a9a !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}
