.inputFieldContainer {
    .booleanField {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .dateField {
        display: flex;
        flex-direction: row;
        .closeIcon {
            height: 40px;
            color: #e62b00;
        }
    }
    .textField {
        display: flex;
        flex-direction: row;
        .closeIcon {
            height: 40px;
            color: #e62b00;
        }
    }
    .radioButtonsContainer {
        margin-bottom: 16px;
        .radioGroup {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .radioButton {
                padding: 9px 3px 9px 9px;
            }
        }
    }
}
