.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .reloadIcon {
        border: 1px solid black;
        border-radius: 4px;
    }
}

.totalFiltersContainer {
    padding: 16px 0 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;

    label {
        margin-left: 0;
        column-gap: 9px;
        span {
            padding: 0;
        }
    }
}

.failed {
    text-decoration: underline dotted;
}

.colSmall {
    min-width: 40px;
}
.colMedium {
    min-width: 156px;
}
.colCatNumber {
    min-width: 44px;
    white-space: nowrap;
}
.colDescription {
    width: 100%;
    min-width: 250px;
}
.colIcon {
    padding: 0 8px !important;
}

.warningRow {
    background-color: lightyellow;
    &:hover {
        background-color: lightgoldenrodyellow !important;
    }
}
.failedRow {
    background-color: rgba(255, 204, 203, 0.5);
    &:hover {
        background-color: rgba(255, 204, 203, 0.85) !important;
    }
}
.statusIcon {
    position: absolute;
    left: -28px;
}

.noResultsCell {
    height: 48px;
    border-right: none !important;
}
