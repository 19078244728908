.loadingSpinnerContainer {
    position: relative;
    top: 150px;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    .tableHeaderContainer {
        display: flex;
        flex-direction: column;
        width: 60%;
        align-self: center;
        margin: 0 auto 10px auto;
        .titleAndButtonRow {
            display: flex;
            flex-direction: row;
            .tableLabel {
                font-weight: 900;
                font-size: 18px;
                text-align: center;
                text-decoration: underline;
                line-height: 2;
            }
            .buttonContainer {
                margin-left: auto;
            }
        }
    }
    .table {
        display: flex;
        flex-direction: row;
        margin: 0 20%;
        .tableHead {
            background-color: rgba(178, 178, 178, 1);
            position: sticky;
            top: 0;
            box-shadow: inset 0 0 0 0 black, inset 0 -2px 0 black;
            z-index: 10;
            .stepNumberHeaderCell {
                font-weight: 900;
                width: 12%;
                border-right: solid 2px black;
                font-size: 18px;
            }
            .descriptionHeaderCell {
                font-weight: 900;
                font-size: 18px;
            }
        }
    }
}
