@use 'src/styles/fonts';

.quickLink {
    text-decoration: underline;
    font-size: 13px !important;
    margin: 0;
    color: black;
    cursor: pointer;
    font-family: fonts.$font-untitled-sans-medium !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: unset;
}

.disableLink {
    text-decoration: none;
    font-style: italic;
    color: lightgray;
    pointer-events: none;
    font-family: UntitledSans, sans-serif, system-ui;
}
