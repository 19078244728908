@use 'src/styles/fonts';

.backLink {
    background-color: white;
    color: black;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 4px;
    font-family: fonts.$font-untitled-sans-medium;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 33px;
    width: 78px;
    justify-content: center;

    &:hover {
        background-color: rgba(229, 229, 229, 1);
        border: 1px solid rgba(229, 229, 229, 1);
        color: black;
    }
}