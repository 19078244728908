.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
    row-gap: 12px;

    .formContainer {
        width: 490px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 24px;

        .errorMessage {
            color: rgb(208, 64, 33);
            font-family: UntitledSansMedium, sans-serif, system-ui;
        }

        .dateAndTimeInputRow {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;

            .spaceBetweenInputs {
                margin-right: 20px;
                width: 100%;
            }

            .dateTimeFieldWidth {
                width: 100%;
            }
        }

        .buttonBox {
            text-align: center;
            margin: 20px;
        }

        .radioBox {
            margin-top: 24px;
        }

        .noProductsMessage {
            text-align: center;
            font-style: italic;
            font-family: UntitledSansMedium, sans-serif, system-ui;
        }

        p {
            width: 100%;
            font-size: 13px;
        }
    }

    h1 {
        font-size: 50px;
        margin-bottom: 0px;
    }

    p {
        width: 25%;
    }

    h2 {
        font-weight: 400;
    }
}
