.noResultsTableContainer {
    border: 1px solid black;
}

.tableContainer {
    border-right: 1px solid black;
    border-radius: 4px;

    table {
        border-collapse: collapse;

        thead>tr>th {
            background-color: lightgray;
            border-top: 1px solid black;
            border-bottom: none;

            &:first-child {
                background-color: white;
                border-top: none;
            }

            &:nth-child(2) {
                border-left: 1px solid black;
            }
        }

        tbody>tr {
            &:hover {
                background-color: rgba(229, 229, 229, 0.5);
            }

            .checkboxCell {
                border-bottom: none;
                background-color: white !important;
            }

            td {
                padding-top: 4px;
                padding-bottom: 4px;

                &:first-child {
                    margin: 0;
                    margin-left: 2px;
                    border-right: 1px solid black;
                }

                &:last-child {
                    padding: 0;
                }
            }

            &:last-child {
                td {
                    border-bottom: 1px solid black;

                    &:first-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .colSmall {
            min-width: 72px;
        }

        .colChannel {
            min-width: 88px;
        }

        .colMedium {
            min-width: 152px;
        }

        .colDescription {
            width: 100%;
        }

        .checkboxCell {
            width: fit-content;
            padding-left: 0;
            padding-right: 0;
            text-align: center;

            label {
                margin: 0;
                margin-right: 8px;

                span {
                    padding: 0;
                }
            }
        }

        .noResultsHeaderCell {
            background-color: lightgray !important;
            border-width: 0 0 1px 0 !important;
            border-color: black !important;
        }

        .noResultsCell {
            text-align: center;
            height: 48px;
            border-right: none !important;
        }

        .noCheckboxColumn {
            border-right: none !important;
            border-left: none !important;
            border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
        }
    }
}