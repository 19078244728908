@use 'src/styles/fonts';

.tableHeaderContainer {
    display: flex;
    align-items: right;
    justify-content: right;
    margin: 20px 0px 20px 0px;
}

.tableContainer {
    border-radius: 4px;

    thead > tr {
        th {
            border-bottom: 1px solid #1e1e1e;
            background-color: white;
            font-weight: bold;
            text-wrap: nowrap;
            padding-left: 0 !important;
            font-family: fonts.$font-untitled-sans-bold;
        }
    }

    tbody > tr {
        td {
            border-bottom: 1px solid #afa99f;
            padding: 8px 16px 8px 0;
            height: 48px;
        }
    }

    .iconCell {
        max-width: 40px !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .indentedFirstCell {
        padding-left: 16px !important;
    }
}
